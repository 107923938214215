<template>
  <b-modal
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    size="xl"
    shadow
    backdrop
    ok-title="Accept"
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">

      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col
              v-for="(day, index) in daysOfWeek"
              :key="day"
              cols="12"
              md="6"
              lg="4"
              class="mb-4"
            >
              <b-card>
                <h5 class="text-center text-primary">
                  {{ $t(day) }}
                </h5>
                <hr>
                <div
                  v-for="(slot, slotIndex) in schedule[index].timeSlots"
                  :key="slotIndex"
                  class="mb-3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="startTime"
                    vid="startTime"
                    rules="required"
                  >
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text>เริ่ม</b-input-group-text>
                      </b-input-group-prepend>

                      <b-form-input
                        id="startTime"
                        v-model="schedule[index].timeSlots[slotIndex].startTime"
                        type="time"
                        required
                      />

                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="endTime"
                    vid="endTime"
                    rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text>สิ้นสุด</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="endTime"
                        v-model="schedule[index].timeSlots[slotIndex].endTime"
                        type="time"
                        required
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-danger"
                          @click="removeTimeSlot(index, slotIndex)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-input-group-append>

                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </div>
                <b-button
                  variant="outline-primary"
                  block
                  @click="addTimeSlot(index)"
                >
                  <i class="bi bi-plus-circle me-2" />{{ $t('Add time period') }}
                </b-button>
              </b-card>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </template>
    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div
          class="float-right"
        >
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="$emit('update:is-sidebar-active', false)"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  BCard,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BInputGroupText,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BModal,
    BCard,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BInputGroupText,
    BInputGroupPrepend,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Cleave,

  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      schedule: [
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
      ],
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, availableTimes,
          } = this.data
          this.dataId = _id
          if (availableTimes.length === 7) {
            this.schedule = availableTimes
          } else {
            this.schedule = [
              { timeSlots: [] },
              { timeSlots: [] },
              { timeSlots: [] },
              { timeSlots: [] },
              { timeSlots: [] },
              { timeSlots: [] },
              { timeSlots: [] },
            ]
          }
        }
      }
    },
  },
  methods: {
    addTimeSlot(dayIndex) {
      this.schedule[dayIndex].timeSlots.push({ startTime: '', endTime: '' })
    },
    removeTimeSlot(dayIndex, slotIndex) {
      this.schedule[dayIndex].timeSlots.splice(slotIndex, 1)
    },
    initValues() {
      this.dataId = null
      this.schedule = [
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
        { timeSlots: [] },
      ]
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            availableTimes: this.schedule.map((e, i) => {
              const dayOfWeek = this.daysOfWeek[i]
              return {
                ...e,
                dayOfWeek,
              }
            }),
          }
          console.log(obj)
          this.$emit('update:show', true)
          store
            .dispatch(`${this.storeModuleName}/updateAvailableTimes`, obj)
          // eslint-disable-next-line no-unused-vars
            .then(result => {
              this.$emit('update:is-sidebar-active', false)
              this.$emit('update:show', false)

              // this.showToast('success', 'bottom-right')
            })
            .catch(error => {
              console.log('fetchUsers Error : ', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
              this.$emit('update:show', false)
              // this.showToast('danger', 'bottom-right')
            })
        }
      })
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
