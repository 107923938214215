<template>
  <b-modal
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    size="xl"
    shadow
    backdrop
    ok-title="Accept"
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">

      <!-- Form -->
      <div
        v-for="(item , index) in options"
        :key="index"
      >
        <b-card
          class="mb-2"
        >
          <validation-observer :ref="`formData${index}`">
            <b-form class="p-2">
              <b-row>
                <b-col cols="9">
                  <!-- refId -->
                  <b-form-group
                    :label="$t('Question')"
                    :label-for="`question${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Question${index}`"
                      :vid="`question${index}`"
                      rules="required"
                    >
                      <b-form-input
                        :id="`question${index}`"
                        v-model="item.question"
                        :name="`question${index}`"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Name')"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('Please input') }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <!-- refId -->
                  <b-form-group
                    :label="$t('Choose a question type')"
                    label-for="register-Type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      vid="Type"
                      rules="required"
                    >
                      <v-select
                        id="register-Type"
                        v-model="item.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="OptionSelection"
                        :reduce="(OptionSelection) => OptionSelection.value"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <hr>
                </b-col>
                <b-col

                  cols="12"
                >
                  <b-row
                    v-for="(list ,indexList) in item.listOption"
                    :key="indexList"
                    align-v="center"
                    :class="indexList !==0 ? 'mt-2' : ''"
                  >
                    <b-form-checkbox
                      :checked="false"
                      class="custom-control-primary mr-ๅ"
                      name="check-button"
                    />
                    <validation-provider
                      #default="{ errors }"
                      :name="`option${index}-${indexList}`"
                      :vid="`option${index}-${indexList}`"
                      rules="required"
                    >
                      <b-form-input
                        :id="`option${index}-${indexList}`"
                        v-model="list.option"
                        :name="`option${index}-${indexList}`"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Name')"
                      />
                    </validation-provider>
                    <validation-provider
                      #default="{ errors }"
                      :name="`price${index}-${indexList}`"
                      :vid="`price${index}-${indexList}`"
                      rules="required"
                    >
                      <cleave
                        :id="`price${index}-${indexList}`"
                        v-model="list.price"
                        :name="`price${index}-${indexList}`"
                        class="form-control ml-2"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('Price')"
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                    <b-button
                      v-if="item.listOption.length>1"
                      class="ml-2"
                      variant="outline-danger"
                      @click="deleteOption(index,indexList)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <b-button
                      v-if="(indexList+1) === item.listOption.length"
                      class="ml-2"
                      variant="outline-primary"
                      @click="addOption(index)"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-row>

                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
                <b-col cols="9" />
                <b-col cols="3">
                  <b-row align-v="center">
                    <b-button
                      class="mr-2"
                      variant="outline-primary"
                      @click="copyOption(item)"
                    >
                      <feather-icon icon="CopyIcon" />
                    </b-button>
                    <b-button
                      v-if="options.length>1"
                      class="mr-2"
                      variant="outline-danger"
                      @click="deleteOption(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <b-form-checkbox
                      v-model="item.required"
                      class="custom-control-primary mr-ๅ"
                      name="check-button"
                      switch
                    />
                    <span>{{ item.required ? $t('Required') : $t('Not required') }}</span>
                  </b-row>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </b-card>
      </div>

      <b-row
        align-v="center"
        class="justify-content-md-center"
      >
        <b-col md="auto">
          <b-button
            variant="primary"
            @click="newOptions()"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('Add option') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div
          class="float-right"
        >
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="$emit('update:is-sidebar-active', false)"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  BCard,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BInputGroupText,
  BInputGroupPrepend,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BModal,
    BCard,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BInputGroupText,
    BInputGroupPrepend,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Cleave,

  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      options: [

      ],
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
    OptionSelection() {
      return [
        { name: this.$t('One option'), value: 'one_option' },
        { name: this.$t('Many options'), value: 'many_options' },
      ]
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.initValues()

        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
        } else {
          const {
            _id, options,
          } = this.data
          this.dataId = _id
          if (options) {
            if (options.length > 0) {
              this.options = options
            } else {
              this.newOptions()
            }
          } else {
            this.newOptions()
          }
        }
      }
    },
  },
  methods: {
    addOption(index) {
      this.options[index].listOption.push({
        option: '',
      })
    },
    deleteOption(index, indexList) {
      this.options[index].listOption.splice(indexList, 1)
    },
    newOptions() {
      this.options.push(
        {
          question: '',
          type: 'one_option',
          listOption: [
            {
              option: '',
              price: 0,
            },
          ],
          required: false,
        },
      )
    },
    deleteOptions(index) {
      this.options.splice(index, 1)
    },
    copyOption(item) {
      this.options.push({
        question: item.question,
        type: item.type,
        listOption: item.listOption,
        required: item.required,
      })
    },
    changeSwitch(event, index) {
      this.options[index].required = event
    },
    selectedData(data) {
      this.options = data.options
    },
    initValues() {
      this.dataId = null
      this.options = []
    },
    async validationForm() {
      const listCheck = []
      for (let index = 0; index < this.options.length; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        const successPass = await this.$refs[`formData${index}`][0].validate().then(val => val)
        listCheck.push(successPass)
      }
      if (listCheck.every(status => status === true)) {
        const obj = {
          id: this.dataId,
          options: this.options.map(e => ({
            ...e,
            listOption: e.listOption.map(j => ({
              ...j,
              price: j.price.replaceAll(',', ''),
            })),
          })),
        }
        console.log(obj)
        this.$emit('update:show', true)
        store
          .dispatch(`${this.storeModuleName}/updateOptions`, obj)
        // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.$emit('update:is-sidebar-active', false)
            this.$emit('update:show', false)

            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.$emit('update:show', false)
            // this.showToast('danger', 'bottom-right')
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t('Error Validator'),
          },
        })
      }
    },
  },
}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    #add-new-user-sidebar {
      .vs__dropdown-menu {
        max-height: 200px !important;
      }
    }
    </style>
